/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  background-color: #202124;
  color: #fff;
  font-family: 'IBM Plex Sans', sans-serif;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

#root {
  display: flex;
  justify-content: center;
}

.App {
  display: block;
  padding: 0em;
  max-width: 1100px;
}

.Search {
    padding: 2em 3em 3em 3em;
}

.Centered {
  display: flex;
  /*justify-content: center;*/
  /*margin-bottom: 1em;*/
  /*display: none;*/
}

.TopNav {
  display: flex;
  justify-content: space-between;
  margin-right: 1em;
}

.Selector {
  display: flex;
  cursor: pointer;
}

.Selector h3 {
  margin-right: 1em;
  text-transform: capitalize;
}

a {
  text-decoration: none;
  color: #fff;
}

a:hover {
  color: #F0A828;
}

.Results {
  margin-top: 1em;
  display: flex;
  flex-wrap: wrap;
}

.ArticleMetadata {
  display: flex;
}

.GithubMetadata {
  display: flex;
  justify-content: start;
  font-size: 0.8em;
}

.GithubMetadata .Dot {
  width:  5px;
  height: 5px;
  margin-top: 7px;
  background-color: #1C73E8;
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5em;
}

.GithubMetadata h5 {
  margin-right: 0px;
  padding-right: 0px;
}

.GithubMetadata .Muted {
  color:  rgba(255,255,255,0.5);
}

.ArticleMetadata > h5 {
  margin-right: 1em;
}

.ArticleContent {
  margin-top: 1em;
  font-size: 0.9em;
  line-height: 1.3em;
  max-width: 80%;
  color:  rgba(255,255,255,0.5);  
  display: flex;
  flex-wrap: wrap;
}

.Higlight {
  color: #F0A828;
}

.ArticleContent > span {
  margin-right: 0.25em;
  cursor: pointer;
}

.ArticleContent span:hover {
  color: #fff;
}

/*.ArticleContent span:hover ~ .Higlight {
  color: #60D9FB;
}*/

.Row {
  margin-bottom: 1.5em;
  width: 50%;
  overflow: hidden;
}

.Row h1 {
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 1em;
  margin-bottom: 0.25em;
  max-width: 80%;
}

.Row h2 {
  max-width: 70%;
}

.Row h5 {
  text-transform: uppercase;
  font-size: 0.5em;
  margin-top: 1em;
  color:  rgba(255,255,255,0.25);  
}

.Row p {
  margin-top: 1em;
  font-size: 0.9em;
  line-height: 1.3em;
  max-width: 80%;
  color:  rgba(255,255,255,0.5);
}

.Row img {
  max-width: 400px;
}

.search-input {
  padding: 10px 10px;
  height: 52px;
  position: relative;
  margin-left: -10px;
}

.search-input::before {
  content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==');
  display: block;
  position: absolute;
  width: 15px;
  z-index: 3;
  height: 15px;
  font-size: 20px;
  top: 11px;
  left: 16px;
  line-height: 32px;
  opacity: 0.6;
}

.search-input > input {
  width: 100%;
  font-size: 18px;
  border: none;
  line-height: 22px;
  padding: 5px 10px 5px 25px;
  height: 32px;
  position: relative;
  background-color: rgba(255,255,255,0.3);
  color:  #fff;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 1em;
}

.search-input ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(255,255,255,0.5);
}

.search-input > input:focus {
  outline: none;
}

h2 {
  color: rgba(255,255,255,0.5);
  margin-bottom: 0.5em;
  font-weight: 300;
  font-size: 1em;
}

h3 {
  text-transform: uppercase;
  font-size: 0.75em;  
  color: #F1AA27;
  margin-bottom: 0.25em;
}

strong {
  font-weight: 500;
}

.Dot {
  background-color: #1C73E8;
  width:  5px;
  height: 5px;
  border-radius: 50%;
  margin-bottom: 0.5em;
}

.Bar {
  /*background-color: #1C73E8;*/
  background-color: #F0A928;
  width:  20px;
  height: 5px;
  /*border-radius: 50%;*/
  margin-top: 2em;
}

footer {
  /*width:  100%;*/
  min-height: 40px;
  border-top: 1px solid rgba(255,255,255,0.1);
  margin-top: 3em;
  padding: 3em;
  display: flex;
  margin-left: 0px;
}

footer h2 {
  color:  rgba(255,255,255,0.75);
}

.FooterColumn {
  font-size: 0.75em;
  line-height: 1.5em;  
  /*max-width: 30%;*/
}

.FooterColumn p {
  font-size: 0.5em;
  line-height: 2em;
  max-width: 80%;
  text-transform: uppercase;
  color: rgba(255,255,255,0.3);
}

.TokenPanel {
    margin-top: 1em;
    margin-bottom: 2em;
}

.TopToken {
  display: inline-block;
  font-size: 0.75em;
  text-transform: uppercase;
  background-color: rgba(255,255,255,0.2);
  cursor: pointer;
  border-radius: 5%;
  /*padding: 0.5em;*/
  /*height: 2em;*/
  margin: 0.25em;
}

.TokenName {
  display: inline-block;
  padding: 0.5em 0.5em 0.5em 0.5em;
  border-right: 1px solid #202124;
}

.TokenHL {
  background-color: #F0A928;
  color: #000;
}

.TokenName:hover {
  background-color: #F0A928;
  color: #000;
}

.TokenCount {
  padding: 0.5em 0.5em 0.5em 0.5em;
  display: inline-block;
  color: rgba(255,255,255,0.4);
  /*background-color: #434342;*/
/*  background-color: #202124;*/
/*  border: 1px solid #4D4D50;*/
  width:  1em;
  text-align: center;
}

.TokenCount:hover span {
  display: none;
}

.TokenCount:hover:before {
  width: 100%;
  content: "+"
}

.TokenCount:hover {
  background-color: #5DB0D7;
  color: #000;
  content:  "+";
}

.TokenCount:active {
  background-color: #F0A928;
}


gray {
  color: #636466;
}

lightgray {
  color: rgba(255,255,255,0.4);
}

.QueryTokens {
  margin-bottom: 1em;
}

.QueryToken { 
  display: inline-block;
  font-size: 1em;
  margin: 0.25em 0.25em 0.25em 0em;
  padding: 0.5em;
  border-radius: 5%;
  color: rgba(255,255,255,0.75);
  border:  1px solid #4D4D50;
  cursor: pointer;
}

.QueryToken:hover {
  background-color: #4D4D50;
  color:  rgba(255,255,255,0.5);
}

.QueryToken:hover .RemoveToken {
  color: #fff;
}

.RemoveToken {
  display: inline-block;
  font-weight: 300;
  color: rgba(255,255,255,0.25);
  margin-left: 0.5em;
}

.Loader {
  max-width: 40px;
}

@media only screen and (max-width: 800px) {
  .App {
    display: block;
  }
  .Search {
    padding: 1.5em;
    /*padding: 0.75em 2em 3em 0.75em;*/
    display: block;
  }
  h2 {
    font-size: 0.75em;
  }
  h3 {
    font-size: 0.6em;
  }
  .Selector {
    flex-wrap: wrap;
  }
  .Row {
    width: 100%;
  }
}
