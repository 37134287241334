/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
  background-color: #000;
  margin: auto;

}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.App {
  padding: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Grid {
  width:  800px;
}

svg {
  margin: 10px 10px 0px 0px;
  /*box-shadow: 20px 20px;*/
}

.Dropdown {
  display: inline-block;
  position: relative;
  margin-right: 1em;
  cursor: pointer;
}

.DropdownContent {
  display: none;
  position: absolute;
  min-width: 14em;
  background-color: #202124;
  border:  1px solid rgba(255,255,255,0.1);
  border-radius: 2%;
  /*border-top: 0.5em solid #000;*/
  /*padding: 1em 1em 0em 0em;*/
  z-index: 10; 
}

.DropdownContent a {
  display: block;
  padding-left: 0.5em;
  padding-top: 1em;
  padding-bottom: 1em;
  color: #FFF;
}

.DropdownContent a:hover {
  background-color: #D19F02;
}

.Dropdown:hover .DropdownContent {
  display: block;
}
